import { useEffect, useState } from 'react';
import groupBuyingApi from 'api/group-buying';
import { filterEmptyObj } from 'utils';

/** 拼团活动信息 */
export const useGroupBuying = (props) => {
  const { goodsId, activityId } = props;
  const [groupList, setGroupList] = useState([]);

  /** 获取待拼团的列表  */
  const getGroupList = async () => {
    const params = filterEmptyObj({
      kdtId,
      goodsId,
      activityId,
      customerId,
      page: 1,
      pageSize: 2,
    });
    const res = await groupBuyingApi.searchWaitingGroupOrderWithPage(params);
    const formateList = res?.items?.map((item) => {
      const groupPeoples = [
        {
          lastName: item?.headName,
          url: item?.headImgUrl,
        },
      ];
      return {
        ...item,
        groupPeoples,
      };
    });
    setGroupList(formateList);
  };

  /** 待拼团的列表倒计时结束的时候重新拉下数据 */
  const onGroupListDone = () => {
    getGroupList();
  };

  useEffect(() => {
    if (!goodsId || !activityId) return;
    getGroupList();
  }, [goodsId, activityId]);

  return {
    goodsId,
    groupList,
    activityId,
    onGroupListDone,
  };
};

export const useGroupDetail = (props) => {
  const { kdtId, customerId, token } = props;
  const [groupBuyingDetail, setGroupBuyingDetail] = useState({});

  useEffect(() => {
    if (!kdtId || !token) return;
    groupBuyingApi
      .getGroupResultDetail(
        filterEmptyObj({
          orderToken: token,
          kdtId,
          customerId,
        }),
      )
      .then((res) => {
        setGroupBuyingDetail(res);
      });
  }, [kdtId, customerId, token]);

  return {
    groupBuyingDetail,
  };
};

/** 获取分享短链  */
export const useShareLink = ({ kdtId, goodsId, activityId }) => {
  const [shareLinkUrl, setShareLinkUrl] = useState('');
  useEffect(() => {
    if (!kdtId || !goodsId || !activityId) return;

    groupBuyingApi
      .getGroupGoodsShareLink(
        filterEmptyObj({
          kdtId,
          goodsId,
          activityId,
        }),
      )
      .then((res) => {
        setShareLinkUrl(res?.url);
      });
  }, [kdtId, goodsId, activityId]);

  return {
    shareLinkUrl,
  };
};
