import React, { useContext, useState, useEffect } from 'react';
import { useThemeContext } from 'hook/global-theme';
import { getQueryString } from 'utils';
import { GoodsLayout } from 'components/page/goods-detail/goods-layout/index';
import {
  ScrollDomContainer,
  ScrollDomContent,
} from 'components/page/goods-detail/goods-layout/hooks/useScrollContainer';
import { useReview } from 'components/page/goods-detail/goods-layout/hooks/index';
import { ProductView } from './components/product-view';
import { GroupBuyingGoods } from '../components/group-buying-goods';
import DefaultLayout from 'components/page/default-layout';
import { getPageDecorationData } from 'helper/goods';
import getInitialData from './get-initial-data';
import { Style } from './style';
import cn from 'classnames';
import { useCart } from 'hook/cart/cart';
import { useWish } from 'hook/goods/wish';
import { buriedPoint } from 'utils/log-buried-point';

const TemplateId = 'product-template';

const GroupBuyingProduct = (props) => {
  const { elemClass } = props;
  const { isMobile } = useThemeContext(); // 是否是手机端
  const { goodsDetail: serverData = {}, shopSetting = {}, shopInfo = {}, userInfo, kdtId, groupBuyingDetail } =
    props?.initialData || {};
  /** token是订单token 也用来标记拼团分享人，有token代表是从分享入口来的 */
  const { goodsId, activityId, token } = getQueryString(props.location.search);
  const customerId = userInfo?.customerId || 0;
  const domain = props?.initialData?.shopInfo.primaryDomain;
  const { currency, language } = shopSetting;
  const theme = props?.initialData?.themeData;
  const [goodsDetail, setGoodDetail] = useState(serverData);

  useEffect(() => {
    if (!goodsDetail?.goodsId) {
      getInitialData({
        kdtId,
        goodsId,
        activityId,
      }).then((res) => {
        setGoodDetail(res?.goodsDetail);
      });
    }
  }, []);

  useEffect(() => {
    if (goodsDetail?.goodsId) {
      buriedPoint('view_item', { source: { goodsDetail } });
    }
  }, [goodsDetail]);

  /** 主题配置 */
  const {
    baseConfig,
    baseConfigInfo = {},
    baseImageStyle = '',
    detailConfigInfo = {},
    globalSetting = {},
    socialShareData = {},
  } = getPageDecorationData(theme?.current?.sections?.[TemplateId], isMobile); // 设置页面装修数据
  const isScroll = !isMobile && (baseConfig?.style === 'style3' || baseConfig?.style === 'style2');
  /** 心愿单 */
  const wishHook = useWish({
    goodsDetail,
    initialData: props.initialData,
    customerId,
  });

  /** 购物 */
  const cartHook = useCart({
    goodsDetail,
    history: props.history,
  });

  /** 评价 */
  const reviewHook = useReview(goodsDetail);

  const baseProps = {
    /** 基础信息 */
    kdtId,
    currency,
    language,
    /** 装修 */
    baseConfigInfo,
    detailConfigInfo,
    socialShareData,
    globalSetting,
    baseImageStyle,
    elemClass,
    /** 数据 */
    goodsDetail,
    userInfo,
    isMobile,
    cartHook,
    reviewHook,
  };

  const baseContainerData = {
    ...baseProps,
    history: props.history,
    wishHook,
    groupBuyingDetail,
    token, // 拼团单单号-如果存在证明当前页是拼团结束页
    activityId,
    goodsId,
    kdtId,
    domain,
  };

  return (
    <Style
      className={cn(ScrollDomContainer, {
        'is-scroll-animation': isScroll,
      })}
    >
      <div className={cn(ScrollDomContent)}>
        <DefaultLayout {...props}>
          <GoodsLayout
            {...baseProps}
            baseContainer={<ProductView {...baseContainerData} />}
            expandContainer={<GroupBuyingGoods currency={currency} language={language} />}
          />
        </DefaultLayout>
      </div>
    </Style>
  );
};

export default GroupBuyingProduct;
