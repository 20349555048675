import styled from 'styled-components';

export const Style = styled.div`
  .goods-buy-button {
    display: flex;
  }
  .operate-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: ${(props) => props.theme.getSpace([40, 56])} !important;
    &.only-one-button {
      width: 100% !important;
      margin-left: 0 !important;
    }
    .guide-text {
      ${(props) => props.theme.getBaseSize(props.theme.h10)};
      font-weight: 400;
    }
    .price-sale {
      ${(props) => props.theme.getBaseSize(props.theme.h11)};
      .price-symbol,
      .price-integer,
      .price-decimal {
        font-weight: 400;
        ${(props) => props.theme.getBaseSize(props.theme.h11)};
      }
    }
    &.theme-sub {
      .price-symbol,
      .price-integer,
      .price-decimal {
        color: var(--color_small_button_text_border) !important;
      }
    }
    &.theme-primary {
      .price-symbol,
      .price-integer,
      .price-decimal {
        color: var(--color_button_text) !important;
      }
    }
  }
`;
