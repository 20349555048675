import groupBuyingApi from 'api/group-buying';
import { isNumber, isNaN } from 'lodash';

/**
 * 服务端请求数据
 */
const getInitialProps = async (query = {}) => {
  const { kdtId, goodsId, activityId, token } = query;
  const customerId = query?.userInfo?.customerId || -1;
  const fetchGoods = () => {
    if (!kdtId || isNaN(Number(goodsId)) || isNaN(Number(activityId))) return {};
    return groupBuyingApi.getGroupActivityGoodsById({
      kdtId,
      goodsId,
      customerId,
      activityId,
    });
  };
  const fetchGroupResultDetail = () => {
    if (!kdtId || !token) return {};
    return groupBuyingApi.getGroupResultDetail({
      orderToken: token,
      kdtId,
      // customerId
    });
  };
  const groupBuyingDetail = await fetchGroupResultDetail();
  const goods = await fetchGoods();

  return {
    goodsDetail: goods,
    groupBuyingDetail,
  };
};

export default getInitialProps;
