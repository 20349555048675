import { useEffect, useState } from 'react';
import groupBuyingApi from 'api/group-buying';

/** 拼团活动信息 */
export const useGroupBuying = (props) => {
  const { goodsId, activityId, customerId } = props;
  const [groupList, setGroupList] = useState([]);
  /** 获取待拼团的列表  */
  const getGroupList = async () => {
    const params = {
      goodsId,
      activityId,
      customerId,
      page: 1,
      pageSize: 200,
    };
    const res = await groupBuyingApi.searchWaitingGroupOrderWithPage(params);
    const formateList = res?.items?.map((item) => {
      const groupPeoples = [
        {
          lastName: item?.headName,
          url: item?.headImgUrl,
        },
      ];
      return {
        ...item,
        groupPeoples,
      };
    });
    setGroupList(formateList);
  };

  /** 待拼团的列表倒计时结束的时候重新拉下数据 */
  const onGroupListDone = () => {
    getGroupList();
  };

  useEffect(() => {
    if (!goodsId || !activityId) return;
    getGroupList();
  }, [goodsId, activityId, customerId]);

  return {
    goodsId,
    groupList,
    activityId,
    onGroupListDone,
  };
};
