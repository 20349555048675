import styled from 'styled-components';

export const Style = styled.div`
  .space {
    margin-top: ${(props) => props.theme.getSpace([16, 24])};
  }
  .goods-price-size {
    .goods-price,
    .cur-price,
    .price-sale,
    .price-symbol,
    .price-integer {
      font-size: inherit !important;
    }
  }
  .detail-desc {
    margin-top: ${(props) => props.theme.getSpace([16, 24])};
    margin-bottom: ${(props) => props.theme.getSpace([0, 40])};
  }
  .vendor-wrapper {
    color: #bfbfbf;
    line-height: 20px;
    margin-top: var(--pm-16-8);
  }
  .col-item {
    margin-top: ${(props) => props.theme.getSpace([6, 8])};
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &.is-sold-out {
    .sku-wrapper {
      opacity: 0.6;
    }
  }
  .custom-number-input {
    border-radius: var(--border_radius_btn) !important;
  }
  .limit-tips {
    display: flex;
    align-items: center;
    color: var(--color_activity_label);
    margin-left: 8px;
    font-size: 12px;
    line-height: 16px;
  }
`;
