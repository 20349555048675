import React, { useState } from 'react';
import { Button, Dialog } from 'zent';
import { Icon } from 'components/base';
import { CountDown } from 'components/page/group-buying/count-down';
import { UserProfileList } from 'components/page/group-buying/profile-list';
import { useIntl } from 'react-intl';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { EffectFade } from 'swiper';
import { GroupBuyingEntryStyle, GroupBuyingItemStyle } from './style';
import SwiperContainer from './swiper-container';
import { useGroupBuying } from './hook';
SwiperCore.use([EffectFade]);

// 是否自动播放：autoPlay={false}
// 轮播速度：autoPlaySpeed={3}

export const GroupItem = ({ item, index, onGroupBuying, isGoodsSoldOut, onTimeDone }) => {
  const intl = useIntl();
  /** 名字其中两位字母处理一下 */
  const getEncryptionName = (name) => {
    const str1 = name.split('');
    if (str1?.length > 3) {
      str1.splice(str1.length - 2, 1, '*');
      str1.splice(str1.length - 3, 1, '*');
    }
    return str1 || 'XX';
  };

  const groupPeoples = [
    {
      lastName: item?.headName,
      url: item?.headImgUrl,
      flagText: intl.formatMessage({
        id: '10defe0be4434692bd5970f468dff09a',
        defaultMessage: '团长',
      }),
    },
  ];

  const { differNum, groupEndAt, headName, activityId, groupOrderId } = item;
  const name = getEncryptionName(headName);

  console.log([...groupPeoples, { type: 'normal' }]);
  return (
    <GroupBuyingItemStyle key={item.groupOrderId} className='group-buying-item'>
      <div className='left'>
        <div className='profile-box-wrapper'>
          <UserProfileList
            size='small'
            list={[...groupPeoples, { type: 'normal' }]}
            styleType='impulse'
            index={index}
          />
        </div>
        <div>
          <div className='group-count'>{name}</div>
          <div className='group-time'>
            <div className='end-in-text'>
              {intl.formatMessage(
                {
                  id: 'ba9da8f64a9a4d47a3a752a634599840',
                  defaultMessage: '{num} participants needed',
                },
                {
                  num: differNum,
                },
              )}
              <span className='dot'>,</span>
              &nbsp;
            </div>
            <div className='rest-time'>
              {intl.formatMessage({
                id: '695af2a94b5e4d06bf22bcb0f6badbc2',
                defaultMessage: '剩余',
              })}
              : &nbsp;
              <CountDown timeEndAt={groupEndAt} styleType='normal' onTimeDone={onTimeDone} />
            </div>
          </div>
        </div>
      </div>
      <div className='right'>
        <Button
          className='group-desc'
          type='primary'
          size='small'
          disabled={isGoodsSoldOut}
          onClick={() => {
            onGroupBuying({
              activityId,
              groupOrderId,
            });
          }}
        >
          <span className='group-buying-text'>
            {intl.formatMessage({
              id: '96a88360cb79486cb9ebed4bf2b801c2',
              defaultMessage: '去凑团',
            })}
          </span>
        </Button>
      </div>
    </GroupBuyingItemStyle>
  );
};
const GroupBuyingList = ({ groupList, onGroupBuying, isGoodsSoldOut, onTimeDone }) => {
  return (
    <div>
      {groupList.map((item, index) => {
        return (
          <GroupItem
            item={item}
            index={index}
            onGroupBuying={onGroupBuying}
            isGoodsSoldOut={isGoodsSoldOut}
            onTimeDone={onTimeDone}
          />
        );
      })}
    </div>
  );
};
const SwiperGroupBuyingList = ({ groupList, onGroupBuying, isGoodsSoldOut, onTimeDone }) => {
  return (
    <Swiper
      direction='vertical'
      paginatorStyle='dots'
      loop={true}
      style={{ width: '100%', height: '138px' }}
      autoplay={true && { delay: 4 * 1000 }}
      noSwiping
      pagination={false}
      slidesPerView={2}
      slidesPerGroup={2}
    >
      {groupList.map((item, index) => {
        return (
          <SwiperSlide key={item.groupOrderId}>
            <GroupItem
              item={item}
              index={index}
              onGroupBuying={onGroupBuying}
              isGoodsSoldOut={isGoodsSoldOut}
              onTimeDone={onTimeDone}
            />
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

export const GroupBuyingEntry = (props) => {
  const { customerId, goodsId, goodsDetail, onGroupBuying } = props;
  const groupActivityDescription = goodsDetail?.groupActivityDescription ?? {};
  const activityId = groupActivityDescription?.activityId;
  const { groupList, onGroupListDone } = useGroupBuying({ goodsId, activityId, customerId });
  const [allGroupVisible, setAllGroupVisible] = useState(false);
  const showViewMore = groupList?.length > 2; // 是否显示查看更多
  const intl = useIntl();
  const isGoodsSoldOut = !goodsDetail?.quantity;
  const onOpenMore = () => {
    if (showViewMore) {
      setAllGroupVisible(true);
    }
  };

  if (!activityId || !groupList?.length) return null;
  return (
    <GroupBuyingEntryStyle>
      <div className='group-buy-title' onClick={onOpenMore}>
        {intl.formatMessage({
          id: '2b1804f6933440b4b37709b62d1c7904',
          defaultMessage: '他们正在拼单中，可参与拼团享优惠',
        })}
        {showViewMore && (
          <div>
            <Icon name='iconic-xiala' size='small' fill='#1A1A1B' />
          </div>
        )}
      </div>
      {groupList?.length > 2 ? (
        <SwiperGroupBuyingList
          groupList={groupList.slice(0, 8)}
          onGroupBuying={onGroupBuying}
          onTimeDone={onGroupListDone}
          isGoodsSoldOut={isGoodsSoldOut}
        />
      ) : (
        <GroupBuyingList
          groupList={groupList.slice(0, 8)}
          onGroupBuying={onGroupBuying}
          onTimeDone={onGroupListDone}
          isGoodsSoldOut={isGoodsSoldOut}
        />
      )}
      <Dialog
        visible={allGroupVisible}
        onClose={() => setAllGroupVisible(false)}
        title={intl.formatMessage({
          id: '4c68450a959e40c3b8a0577a49397add',
          defaultMessage: '可参与的拼团',
        })}
        style={{
          maxHeight: '80vh',
          overflow: 'scroll',
        }}
      >
        <GroupBuyingList
          groupList={groupList}
          onGroupBuying={onGroupBuying}
          onTimeDone={onGroupListDone}
          isGoodsSoldOut={isGoodsSoldOut}
        />
      </Dialog>
    </GroupBuyingEntryStyle>
  );
};
